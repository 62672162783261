<template>
  <div class="navbar-container d-flex content justify-content-between align-items-center">
    <div class="d-flex">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>
      <!-- Left Col -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <!-- Bookmarks Container -->
        <i
          class="fas fa-chevron-left mx-1 cursor-pointer"
          @click="$router.go(-1)"
        />
        <bookmarks v-if="userData.role_name === 'customer' || userData.role_name === 'dispatcher'" />
      </div>
    </div>

    <div class="d-block d-lg-none">
      <b-img
        :src="logo"
        height="50"
      />
    </div>

    <b-navbar-nav class="nav align-items-center">
      <!-- <locale /> -->
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BNavbarNav, BImg,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    // Locale,
    // SearchBar,
    DarkToggler,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      logo: require('@/assets/images/logo/walleat_logo_white.svg'),
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
